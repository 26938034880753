@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");

/* Fonts */
body {
  font-family: "Poppins", "Helvetica", "sans-serif";
}

/* Padding */
.progress-container {
  margin-top: 5rem;
  padding: 0 16px;
}

/* Progress bar styles */
.progress-bar {
  background-color: transparent;
  border: 3px #f4f4f4 solid;
  border-radius: 20px;
  position: relative;
  margin: 4px 0 16px 0;
  height: 30px;
  width: 100%;
}

.progress-bar .completed-bar {
  max-width: 100%;
  background: linear-gradient(to left, #ed1e28, #fe6d62);
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  transition: width 2s ease 0.4s;
  padding: 0 8px;
  position: relative;
}

.progress-bar .completed-bar .completed-bar__dollars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.progress-bar .completed-bar .completed-bar__dollars .dollar {
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  color: #ffffff;
  opacity: 0.7;
  animation: dollarMove 3s linear infinite;
}

.progress-bar .completed-bar .completed-bar__truck {
  position: absolute;
  right: -30px; /* Przesunięcie, aby pozostała widoczna */
  transform: translateX(0);
  z-index: 2;
}

/* Keyframe animations */
@keyframes dollarMove {
  0% {
    bottom: 0;
    opacity: 0.7;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    bottom: 100%;
    opacity: 0;
  }
}

/* Progress information styles */
.progress-information {
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
}

.progress-information-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 0 0 1rem 0;
  font-weight: 300;
  font-size: 1rem;
  color: black;
}

.text-colour--faded-60 {
  color: rgba(0, 0, 0, 0.6);
}

.text-colour--primary-red--80 {
  color: rgba(237, 30, 40, 0.8);
}
